<template>
  <div>
    <component :is="component" v-if="component_show"></component>
  </div>
</template>

<script>
import store from "@/store";
import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";
import { routerParams } from "@/libs/utils/routerParams";

export default {
  name: "ProjectDetail",
  setup() {
    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      homepage_type: computed(() => {
        let storage = JSON.parse(localStorage.getItem("project-selected"));
        return (
          store.state.projects.selected.homepage_type || storage.homepage_type
        );
      }),
      component_show: false,
      component: null,
    });

    onMounted(() => {
      let component_path = ''
      switch (event.homepage_type) {
        case "simple":
          component_path = "common/project-detail/components/simple";
          break;
        case "complex":
          component_path = "common/project-detail/components/complex";
          break;
        case "dashboard":
          component_path = "common/project-detail/components/dashboard";
          break;
        case "green":
          component_path = "construction/green";
          break;
        case "health":
          component_path = "construction/health";
          break;
        case "nearly_zero":
          component_path = "construction/nearly_zero";
          break;
        case "zero_carbon":
          component_path = "construction/zero_carbon";
          break;
        case "jsf":
          component_path = "jsf35/homepage";
          break;
        case "scds":
	      component_path = "scds/homepage";
	      break;
        case "bjdhgjzx":
          component_path = "bjdhgjzx/homepage";
          break;
        case "shlfgc5":
          component_path = "shlfgc5/homepage";
          break;
        case "xjjkyzeb":
          component_path = "xjjkyzeb/homepage";
          break;
        default:
          component_path = "common/project-detail/components/simple";
          break;
      }

      event.component = () => import(`@/views/front/${component_path}/index.vue`);
      event.component_show = true;

	  console.log('component_path',component_path)

    });
    return {
      ...toRefs(event),
    };
  },
  // beforeRouteLeave(to,from,next){
  //   document.body.removeAttribute('data-theme')
  //   next()
  // }
};
</script>

<style lang="scss"></style>
